

div.badgeDone {
    display: inline;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 67px;
    text-align: center;
    /* margin: 43px; */
    margin: 28px;
    padding: 0;
  }
  
  .cardCameraTitle {
    position: absolute;
    top: 54px;
    left: 20px;
    right: 20px;
    text-align: center;
    line-height: 1;
    color: #6e6e6e;
  }
  
  .cardCameraIcon {
    position: absolute;
    top: 28px;
    left: 0;
    font-size: 23px;
    right: 0;
  }