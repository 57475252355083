
.i-row { display: flex; flex-direction: row; }
.i-row.h-left {    justify-content: flex-start; }
.i-row.h-right {   justify-content: flex-end; }
.i-row.h-center {  justify-content: center; }
.i-row.h-space {   justify-content: space-between; }
.i-row.h-space2 {  justify-content: space-around; }
.i-row.h-full > div { flex-grow: 1; }

.i-row.v-top {     align-items: flex-start }
.i-row.v-bottom {  align-items: flex-end }
.i-row.v-center {  align-items: center }
.i-row.v-full {    align-items: stretch }
.i-row.v-full > div { height: auto; }
.i-row.v-baseline { align-items: baseline }

.i-col { display: flex; flex-direction: column; }

.i-col.h-left {      align-items: flex-start  }
.i-col.h-right {     align-items: flex-end  }
.i-col.h-center {    align-items: center; }
.i-col.h-full > div {  width: auto; } 

.i-col.v-top {       justify-content: flex-start; }
.i-col.v-bottom {    justify-content: flex-end; }
.i-col.v-center {    justify-content: center }
.i-col.v-full > div { flex-grow: 1; }
.i-col.v-space {     justify-content: space-between; }
.i-col.v-space2 {    justify-content: space-around; }

.grow-0 { flex-grow: 0 !important; }
.grow-1 { flex-grow: 1 !important; }
