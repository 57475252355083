  /* #root {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(#e66465, #9198e5);
  } */
  
  /* #root>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden
  } */
  
  /* .app>div { */
  .PanelLikeCards>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  /* .PanelLikeCards .row {
    flex-direction: row !important;
  }
  
  .PanelLikeCards .row>* {
    margin: 5px;
  } */
  
  /* .PanelLikeCards h1 {
    font-family: 'Damion', cursive;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  } */
  
  .PanelLikeCards h2 {
    color: #fff;
  }
  
  .PanelLikeCards .swipe {
    position: absolute;
    transition: transform 50ms; 
     /* ease-in-out 0; */
  }
  
  .PanelLikeCards .cardContainer {
    width: 90vw;
    max-width: 60vh;
    /* max-width: 260px; */
    /* height: 300px; */
    height: 60vh;
    z-index: 1;
    margin: 20px;
  }
  
  .PanelLikeCards .card {
    position: relative;
    background-color: #fff;
    width: 60vh;
    height: 60vh;
    /* width: 80vw;
    max-width: 260px;
    height: 300px; */
    /* box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30); */
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .PanelLikeCards .cardContent {
    width: 100%;
    height: 100%;
  }
  
  /* .swipe:last-of-type {
  
  } */
  
  /* .PanelLikeCards .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  } */
  
  .PanelLikeCards .infoText {
    width: 100%;
    height: 28px;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  /* .PanelLikeCards .buttons {
    margin: 20px;
    display: flex;
  } */
  
  /* .PanelLikeCards .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 0 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  } */
  
  /* .PanelLikeCards .buttons button:hover {
    transform: scale(1.05);
  } */
  
  @keyframes popup {
    0%   { transform: scale(1,1) }
    10%  { transform: scale(1.1,1.1) }
    30%  { transform: scale(.9,.9) }
    50%  { transform: scale(1,1) }
    57%  { transform: scale(1,1) }
    64%  { transform: scale(1,1) }
    100% { transform: scale(1,1) }
  }