

  .model-footer {
    text-align: center;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, transparent, #000000bb 25%, #00000099);
    padding-bottom: 40px;
    padding-top: 20px;
  }
  
  .src-img, .painted-img {
    max-height: 75vh;
    max-width: 100vw;
    width: auto;
    margin: auto;
  }
  
  #model-loader { 
    text-align: center;
    display: block;
    font-size: 60px;
    position: fixed;
    top: 40vh;
    left: 0;
    right: 0;
  }
  